
<template>
  <div id="user-edit-tab-info">
    <account-info-form />
  </div>
</template>

<script>
import AccountInfoForm from '@/components/forms/AccountInfoForm.vue'
export default {
  components: {
    AccountInfoForm
  },
}
</script>
